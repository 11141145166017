import type { HomeBannerType } from '@st/core/composables/useBanners'

type PlatformBanners = Record<'auth' | 'nonAuth' | 'secondary', HomeBannerType>

const DESKTOP_BANNERS: PlatformBanners = {
  auth: 'desktopHomePrimaryAuthenticated',
  nonAuth: 'desktopHomePrimaryNotAuthenticated',
  secondary: 'desktopHomeSecondary',
}

const MOBILE_BANNERS: PlatformBanners = {
  auth: 'mobileHomeAuthenticated',
  nonAuth: 'mobileHomeNotAuthenticated',
  secondary: 'mobileMenu',
}

export function useHomeBanners(options?: { isMobile: boolean }) {
  const { auth, nonAuth, secondary } = options?.isMobile
    ? MOBILE_BANNERS
    : DESKTOP_BANNERS

  const {
    banners: primaryAuthenticatedBanners,
    isLoading: isLoadingPrimaryAuthBanner,
  } = useBanners(auth)
  const {
    banners: primaryNotAuthenticatedBanners,
    isLoading: isLoadingPrimaryNotAuthBanner,
  } = useBanners(nonAuth)

  const { banners: secondaryBanners, isLoading: isLoadingSecondaryBanners } =
    useBanners(secondary)

  const authMainBanner = computed(() => primaryAuthenticatedBanners.value[0])

  const nonAuthMainBanner = computed(
    () => primaryNotAuthenticatedBanners.value[0],
  )

  const isLoading = computed(() => {
    const isLoadingMainBanner =
      isLoadingPrimaryAuthBanner.value || isLoadingPrimaryNotAuthBanner.value
    if (options?.isMobile) return isLoadingMainBanner
    return isLoadingSecondaryBanners.value || isLoadingMainBanner
  })

  const { isMounted } = useIsOnMounted()

  return {
    secondaryBanners,
    isLoadingSecondaryBanners,
    authMainBanner,
    nonAuthMainBanner,
    isLoading,
    isMounted,
  }
}
export { DESKTOP_BANNERS, MOBILE_BANNERS }
