import { filterObjectByFilledValues } from '@st/utils'
import { useGoogleRedirect } from './useGoogleRedirect'

export type Providers = 'google'
export interface AuthParams {
  [key: string]: string | undefined
  language: any
}

export function useAuthProviders() {
  const { t, locale } = useI18n()
  const { open } = useToast()

  const currentLoadingProvider = ref<Providers | null>(null)

  const { handleGoogleRedirect } = useGoogleRedirect()

  async function auth(provider: Providers, params?: AuthParams) {
    const authProviderFn = {
      google: handleGoogleRedirect,
    }[provider]

    const localeFallback = (locale.value || 'en') as any
    const filteredParams: AuthParams = params
      ? filterObjectByFilledValues({
          ...params,
          language: params.language || localeFallback,
        })
      : { language: localeFallback }

    try {
      currentLoadingProvider.value = provider
      await authProviderFn(filteredParams)
    } catch {
      console.error(`Failed to auth with ${provider}`)
      open({
        label: t('authorization.errorMessages.somethingWentWrong'),
        type: 'negative',
      })
    } finally {
      currentLoadingProvider.value = null
    }
  }

  return {
    auth,
    currentLoadingProvider,
  }
}
