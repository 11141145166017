<template>
  <div :class="{ mobile }" class="wrapper" data-t="about">
    <div class="about" :class="{ shrinked: !isShown }" data-t="about-content">
      <h1>{{ t('about.title') }}</h1>
      <div class="content">
        {{ t('about.titleContent') }}
      </div>
      <h2>{{ t('about.crypto') }}</h2>
      <div class="content">
        {{ t('about.cryptoContent') }}
      </div>
      <h2>{{ t('about.opportunities') }}</h2>

      <div class="content">
        <div class="list-item">
          <StIcon name="check" class="icon" :size="mobile ? 20 : 24" />
          {{ t('about.oportunityFirst') }}
        </div>
        <div class="list-item">
          <StIcon name="check" class="icon" :size="mobile ? 20 : 24" />
          {{ t('about.oportunitySecond') }}
        </div>
        <div class="list-item">
          <StIcon name="check" class="icon" :size="mobile ? 20 : 24" />
          {{ t('about.oportunityThird') }}
        </div>
        <div class="list-item">
          <StIcon name="check" class="icon" :size="mobile ? 20 : 24" />
          {{ t('about.oportunityFourth') }}
        </div>
      </div>
      <h2>{{ t('about.guarantees') }}</h2>
      <div class="content">
        {{ t('about.guaranteesContent') }}
      </div>
      <div v-if="!isShown" class="about-overlay" />
    </div>
    <div class="expand">
      <StButton
        type="gray"
        size="m"
        data-t="expand-button"
        :icon="isShown ? 'chevron-top' : 'chevron-down'"
        :label="isShown ? t('about.minimize') : t('about.maximize')"
        @click="isShown = !isShown"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n()

const isShown = ref(false)

defineProps<{ mobile?: boolean }>()
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: var(--spacing-450) 0;
}

.about {
  position: relative;
  margin-bottom: var(--spacing-100);
  font: var(--desktop-text-md-medium);
  color: var(--text-secondary);

  h1,
  h2 {
    margin: var(--spacing-125) 0;
    color: var(--palette-light-1000);
  }

  h1 {
    font: var(--desktop-text-2xl-semibold);
  }

  h2 {
    font: var(--desktop-text-xl-medium);
  }

  .content {
    margin-bottom: var(--spacing-300);
  }

  .list-item {
    display: flex;
    vertical-align: center;
  }

  .icon {
    flex-shrink: 0;
    margin-right: var(--spacing-075);
  }
}

.about-overlay {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 60%;

  background-color: var(--background-base);

  mask-image: linear-gradient(180deg, transparent, var(--background-base));
}

.shrinked {
  overflow-y: hidden;
  height: 108px;
}

.expand {
  display: flex;
  justify-content: center;
}

.mobile {
  &.wrapper {
    padding: var(--spacing-450) var(--spacing-200);
  }

  & h1 {
    margin: 0;
    margin-bottom: var(--spacing-150);
    font: var(--mobile-title-1-semibold);
  }

  & h2 {
    margin: var(--spacing-075) 0;
    font: var(--mobile-title-2-semibold);
  }

  & .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);

    margin-bottom: var(--spacing-150);

    font: var(--mobile-text-content-regular);
  }

  .shrinked {
    height: 208px;
  }

  .about {
    margin-bottom: var(--spacing-200);
  }
}
</style>
